<template>
  <base-tooltip-button
    :fab="true"
    :small="true"
    :disabled="disabled"
    @click="onInclude"
  >
    <template v-slot:icon-name>
      {{ iconName }}
    </template>
    <template v-slot:toolTip>
      {{ toolTip }}
    </template>
  </base-tooltip-button>
</template>

<script>
// design
import { iconCheckbox, iconCheckboxBlank } from "@/design/icon/iconConst";

// model
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "IncludeFieldButton",
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  props: {
    /**
     * Form Field
     * @type {{ id:number, name:string, label:string, include:boolean }}
     */
    field: undefined,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconCheckbox,
      iconCheckboxBlank,
      eventNameClick: eventNames.click
    };
  },
  computed: {
    iconName() {
      return this.fieldInclude ? this.iconCheckbox : this.iconCheckboxBlank;
    },
    fieldInclude: {
      get() {
        return this.field?.include ?? false;
      },
      set(value) {
        if (this.field) {
          this.field.include = value;
        }
      }
    },
    fieldLabel() {
      return this.field?.label ?? this.field?.name ?? "Field";
    },
    toolTip() {
      return `Include: ${this.fieldLabel} in search results`;
    }
  },
  methods: {
    onInclude() {
      try {
        this.fieldInclude = !this.fieldInclude;
      } catch (e) {
        console.error(e.toString());
      }
    }
  }
};
</script>
